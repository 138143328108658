import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "components/layout"
import SEO from "components/seo"

import { Cover } from "components/fostive/cover/cover"
import { FooterBrand } from "components/fostive/footer-brand/footer-brand"

import { replaceUnicode } from "src/utils/mixins"

// import bgSample from "assets/img/bg/bg-home.jpg"

// graphql mixins
import { findBlockGql } from "src/utils/graphql/mixins"

export default function AboutPage() {
  const rawData = useStaticQuery(graphql`
    query {
      wordPress {
        pageBy(uri: "about") {
          blocks {
            name
            ... on WordPress_AcfCoverMainBlock {
              dynamicContent
              originalContent
              coverMain {
                img {
                  sourceUrl
                }
                title
                text
              }
            }
          }
        }
      }
    }
  `)

  const blocks = rawData.wordPress.pageBy.blocks
  const acfCoverMain = findBlockGql(blocks, "acf/cover-main")

  return (
    <>
      <Layout footer={false}>
        <SEO title="About" />

        <Cover
          variant="basic"
          theme="dark"
          img={acfCoverMain.coverMain.img.sourceUrl}
          imgHeight="h-vh-100"
          className="cover-full text-center"
          contentMaxWidth="mw-100"
        >
          <>
            <div className="custom-sidebar">
              <div>
                <h2 className="mb-lg-4 mb-3 animated fadeInUp delayp2">
                  {replaceUnicode(acfCoverMain.coverMain.title)}
                </h2>
                <p className="animated fadeInUp delayp4">
                  {replaceUnicode(acfCoverMain.coverMain.text)}
                </p>
              </div>
              <div>
                <FooterBrand mobile={true} absolute={true} />
              </div>
            </div>
          </>
        </Cover>
      </Layout>
    </>
  )
}
